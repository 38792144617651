<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      :visible="true"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="hideModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0 text-danger"
          >
            {{ $t('Descida de preço') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >
          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <b-card
              no-body
              class="card-employee-task m-0 border-0 box-shadow-0"
            >
              <b-card-body
                class="p-0"
              >
                <b-row>
                  <b-col cols="12">
                    <preview-item
                      v-for="(listing, index) in listings"
                      :key="`listing-id-${index}`"
                      :listing="listing"
                      :index="index"
                      :extra-class="['employee-task']"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-container>
        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-start"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BCard, BCardBody, BRow, BCol, BContainer, BButton,
} from 'bootstrap-vue'
import PreviewItem from '@/views/modules/components/blockListingSimple.vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { buildDataToListiew } from '../helper'

export default {
  components: {
    BSidebar,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BContainer,
    BButton,
    PreviewItem,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  mixins: [sizeIconsAction],
  props: {
    data: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  methods: {
    hideModal() {
      this.$emit('closeModal')
    },
  },
  setup(props) {
    const listings = buildDataToListiew(props.data) || []

    return {
      listings,
      configSettingsScroll,
    }
  },
}
</script>
